  
import React, {Fragment} from "react";

const AboutUs = () => (
  <Fragment>
   <section className="main-about-us">
		<div className="sec-shape">
			<span className="shape shape1"><img src="assets/images/shape3.png" alt="Shape" /></span>
			<span className="shape shape2"><img src="assets/images/shape4.png" alt="Shape" /></span>
		</div>
		<div className="container">
			<div className="row" id="about-us">
				<div className="col-lg-6 order-lg-1 order-2">
					<div className="about-content wow right-animation" data-wow-delay="0.4s">
						<span className="sub-title">ABOUT US</span>
						<h2 className="h2-title">We’re Design Trend Creators</h2>
						<h3 className="h3-title">Website & Mobile App Design</h3>
						<div className="about-text">
							<p>Mauris dui metus, tristique id dui in, rhoncus vestibulum nisi. Nulla fermentum dictum odio ac scelerisque. Quisque vitae suscipit sapien, tincidunt euismod leo. Fusce placerat ipsum a odio hendrerit, quis aliquam dolor aliquet.</p>
						</div>
						<ul>
							<li>Aliquam sit amet arcu vitae ex venenatis commodo sit amet vitae tortor raesent dictum orci ut dignissim vulputate.</li>
							<li>Etiam in dolor non eros euismod tincidunt. Proin venenatis tellus a risus efficitur gravida non non lacus.</li>
						</ul>
						<a href="about-us.html" className="sec-btn" title="Read More"><span>Read More</span></a>
					</div>
				</div>
				<div className="col-lg-6 order-lg-2 order-1 wow left-animation" data-wow-delay="0.4s">
					<div className="about-img-box">
						<div className="about-img img1 back-img" style={{backgroundImage: `url('assets/images/about-img1.jpg')`}}></div>
						<div className="about-img img2 back-img" style={{backgroundImage: `url('assets/images/about-img2.jpg')`}}></div>
						<div className="about-img img3 back-img" style={{backgroundImage: `url('assets/images/about-img3.jpg')`}}></div>
					</div>
				</div>
			</div>
		</div>
	</section>
  </Fragment>
);

export default AboutUs;