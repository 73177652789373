/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Navbar = ({ history }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <nav className="main-navigation">
    <button className={`toggle-button navbar-burger burger ${isOpen && "is-active"}`}
            aria-label="menu"
            aria-expanded="false"
            onClick={() => setOpen(!isOpen)}> 
        <span></span>
        <span></span>
        <span></span>
    </button>
    <ul className="menu">
        <li><NavLink className="navbar-item" activeClassName="active" to="/" exact>Home</NavLink></li>
        <li><NavLink className="navbar-item" activeClassName="active" to="/about"> About </NavLink></li>
        <li><NavLink className="navbar-item" activeClassName="active" to="/services"> Services </NavLink></li>
        <li className="sub-items">
            <a href title="Pages">Pages</a>
            <ul className="sub-menu">
                <li>
                <NavLink className="navbar-item" activeClassName="active" to="/portfolio" exact>Portfolio</NavLink>
                </li>
                <li>
                <NavLink className="navbar-item" activeClassName="active" to="/team" exact>Team</NavLink>
                </li>
                <li>
                <NavLink className="navbar-item" activeClassName="active" to="/faq" exact>FAQ</NavLink>
                </li>
            </ul>
        </li>
        <li>
                <NavLink className="navbar-item" activeClassName="active" to="/contact" exact>Contact Us</NavLink>
                </li>
    </ul>
</nav>
  );
};

export default Navbar;