  
import React, {Fragment} from "react";

const OtherBanner = () => (
  <Fragment>
   <section className="main-banner inner-banner back-img" id="main-banner" style={{backgroundImage: `url('assets/images/contact-banner.jpg')`}}>
		<div className="container">
			<div className="row">
				<div className="col-12">
					<div className="banner-content">
						<h1 className="h1-title wow fadeup-animation" data-wow-delay="0.2s">Contact Us</h1>
						<div className="breadcrumb-box wow fadeup-animation" data-wow-delay="0.4s">
							<ul>
								<li><a href="index-2.html" title="Home">Home</a></li>
								<li>Contact Us</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
  </Fragment>
);

export default OtherBanner;