import React, { Fragment } from "react";
const Contact = () => (
  <Fragment>
    <section className="de_contact-page section-space-y3">
      <div className="container position-relative d-flex justify-content-center de-z-index1">
        <div className="row my-2 mx-2">
          <div className="col-md-6"> <img src="assets/images/d2c-contact.svg" alt="IMG" /> </div>
          <div className="col-md-6">
            <h2 className="form-title text-white">Contact us</h2>
            <p className="justify text-white">Have an enquiry or would like to give us feedback?<br />Fill out the form below to contact our team.</p>
            <form>
              <div className="form-group pt-2 pl-1"> <label className="text-white" for="exampleInputName">Your name</label> <input type="text" className="form-control" id="exampleInputName" /> </div>
              <div className="form-group pl-1"> <label className="text-white" htmlFor="exampleInputEmail1">Your email address</label> <input type="email" className="form-control" id="exampleInputEmail1" /> </div>
              <div className="form-group pl-1"> <label className="text-white" htmlFor="exampleFormControlTextarea1">Your message</label> <textarea className="form-control" id="exampleFormControlTextarea1" rows="5"></textarea> </div>
              <div className="row">
                <div className="col-md-3 offset-md-9"><button type="submit" className="sec-btn mt-3">Send</button></div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>

  </Fragment>
);

export default Contact;