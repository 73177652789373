  
import React, {Fragment} from "react";

const Banner = () => (
  <Fragment>
      <section className="main-banner" id="main-banner">
		<div className="sec-shape">
			<span className="shape shape1 animate-this"><img src="assets/images/shape1.png" alt="Shape" /></span>
			<span className="shape shape2 animate-this"><img src="assets/images/shape2.png" alt="Shape" /></span>
			<span className="shape shape3 animate-this"><img src="assets/images/shape3.png" alt="Shape" /></span>
			<span className="shape shape4 animate-this "><img src="assets/images/shape2.png" alt="Shape" /></span>
			<span className="shape shape5 animate-this"><img src="assets/images/shape1.png" alt="Shape" /></span>
		</div>
		<div className="container">
			<div className="row">
				<div className="col-lg-6">
					<div className="main-banner-slider wow fadeup-animation" data-wow-delay="0.6s">
						<div className="banner-slider">
							<div className="banner-img back-img" style={{backgroundImage: `url('assets/images/banner-img1.jpg')`}}></div>
							<div className="banner-img back-img" style={{backgroundImage: `url('assets/images/banner-img2.jpg')`}}></div>
						</div>
					</div>
				</div>
				<div className="col-lg-6">
					<div className="banner-content">
						<span className="sub-title wow fadeup-animation color-sec" data-wow-delay="0.4s">WELCOME TO D-CABIN</span>
						<h1 className="h1-title wow fadeup-animation" data-wow-delay="0.5s">We Bring Idea’s To Digital Life</h1>
						<p className="wow fadeup-animation" data-wow-delay="0.6s">Etiam eleifend neque mi, vitae dapibus risus consectetur vel. In condimentum tempus velit, a sodales mi.</p>
						<div className="banner-mail-box wow fadeup-animation" data-wow-delay="0.7s">
							<form className="dzSubscribe" action="https://shivaa.dexignzone.com/shivaa-HTML/homepage_4/assets/script/mailchamp.php" method="post">
								<input name="dzEmail" required="required" type="email" placeholder="Email Address..." />
								<button name="submit" value="Submit" type="submit" className="sec-btn"><span>Subscribe Now</span></button>
								<div className="dzSubscribeMsg"></div>
							</form>
						</div>
						<div className="banner-call wow fadeup-animation" data-wow-delay="0.8s">
							<a href title="Call on +91 987 654 321"><span className="icon"><img src="assets/images/call-icon.png" alt="Call Icon" /></span>Call Now:<span>+91 987 654 321</span></a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
  </Fragment>
);

export default Banner;
