  
import React, {Fragment} from "react";

const GetStartNow = () => (
  <Fragment>
    <section className="main-get-started">
		<div className="container">
			<div className="row">
				<div className="col-12">
					<div className="get-started-box wow fadeup-animation" data-wow-delay="0.4s">
						<div className="circle-shape"><span></span></div>
						<div className="get-started-text">
							<h4 className="h4-title">Ready To Get Started?</h4>
							<p>Tell us your problem and get the services from you</p>
						</div>
						<a href="contact.html" className="sec-btn" title="Join Us Now"><span>Join Us Now</span></a>
					</div>
				</div>
			</div>
		</div>
	</section>
  </Fragment>
);

export default GetStartNow;