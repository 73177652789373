import React, {Fragment} from 'react'
import GetStartNow from '../components/GetStartNow';

function Footer() {
  return (
      <Fragment>
		  <GetStartNow />
    <footer className="site-footer">
		<div className="sec-shape">
			<span className="shape shape1"><img src="assets/images/shape4.png" alt="Shape1" /></span>
			<span className="shape shape2"><img src="assets/images/shape1.png" alt="Shape2" /></span>
			<span className="shape shape3 "><img src="assets/images/shape2.png" alt="Shape3" /></span>
		</div>
		<div className="container">
			<div className="row">
				<div className="col-lg-3">
					<div className="footer-info">
						<div className="footer-logo">
							<a href="index-2.html" title="Shivaa">
								{/* <img src="assets/images/logo.png" alt="Logo" /> */}
								<img src="assets/images/D-logo-3.png" alt="Logo" />
							</a>
						</div>
						<div className="footer-contact-box">
							<div className="footer-contact-link">
								<span className="icon"><i className="fa fa-map-marker" aria-hidden="true"></i></span>
								<a href title="1247/Plot No. 39, 15th Phase, Colony, Kukatpally, Hyderabad">1247/Plot No. 39, 15th Phase, Colony, Kukatpally, Hyderabad</a>
							</div>
						</div>
						<div className="footer-contact-box">
							<div className="footer-contact-link">
								<span className="icon"><i className="fa fa-envelope" aria-hidden="true"></i></span>
								<a href title="info@gmail.com">info@gmail.com</a>
								<a href title="services@gmail.com">services@gmail.com</a>
							</div>
						</div>
						<div className="social-icon">
							<a href title="Follow on Facebook"><i class="fab fa-2x fa-facebook"></i></a>
							<a href title="Follow on Instagram"><i class="fab fa-2x  fa-twitter"></i></a>
							<a href title="Follow on Twitter"><i class="fab fa-2x fa-instagram"></i></a>
						</div>
					</div>
				</div>
				<div className="col-lg-3 col-md-6">
					<div className="our-links">
						<h3 className="h3-title footer-title">Our Links</h3>
						<ul>
							<li><a href="index-2.html" title="Home">Home</a></li>
							<li><a href="about-us.html" title="About Us">About Us</a></li>
							<li><a href="services.html" title="Services">Services</a></li>
							<li><a href="blog-list.html" title="Blog">Blog</a></li>
							<li><a href="contact.html" title="Contact Us">Contact Us</a></li>
							<li><a href="faq.html" title="FAQ">FAQ</a></li>
							<li><a href="team.html" title="Team">Team</a></li>
							<li><a href="pricing.html" title="Pricing">Pricing</a></li>
							<li><a href="portfolio.html" title="Portfolio">Portfolio</a></li>
						</ul>
					</div>
				</div>
				<div className="col-lg-3 col-md-6">
					<div className="our-services">
						<h3 className="h3-title footer-title">Our Services</h3>
						<ul>
							<li><a href="services.html" title="Web Design">Web Design</a></li>
							<li><a href="services.html" title="Web Development">Web Development</a></li>
							<li><a href="services.html" title="SEO Marketing">SEO Marketing</a></li>
							<li><a href="services.html" title="Research &amp; Idea">Research &amp; Idea</a></li>
							<li><a href="services.html" title="Mobile App">Mobile App</a></li>
							<li><a href="services.html" title="UI / UX">UI / UX</a></li>
						</ul>
					</div>
				</div>
				<div className="col-lg-3">
					<div className="free-consultation">
						<span className="icon">
							<img width="66" height="62" src="assets/images/bot.svg" alt="Message Icon" />
						</span>
						<p>Need Free Consultation?</p>
						<h3 className="h3-title"><a href="contact.html" title="Get In Touch With Us">Get In Touch With Us</a></h3>
					</div>
				</div>
			</div>
		</div>
	</footer>
	<div className="footer-last">
		<div className="container">
			<div className="row align-items-center">
				<div className="col-lg-6">
					<div className="copy-right">
						<p>Copyright © 2021 <a href="https://dcabin.in/" target="_blank">Dcabin</a>. All rights reserved.</p>
					</div>
				</div>
				<div className="col-lg-6">
					<div className="footer-last-link">
						<ul>
							<li><a href title="Privacy Policy">Privacy Policy</a></li>
							<li><a href title="Terms Of Service">Terms Of Service</a></li>
							<li><a href title="Legal">Legal</a></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>

	<a href="#main-banner" className="scroll-top" id="scroll-to-top">
		<i className="fa fa-arrow-up" aria-hidden="true"></i>
	</a>
    </Fragment>
    )
}

export default Footer