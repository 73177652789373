
import React, { Fragment } from "react";
import OtherBanner from '../components/OtherBanner';

const Portfolio = () => (
	<Fragment>
		<OtherBanner />
		<section className="main-portfolio-tabbing">
			<div className="container">

				<h1 className="title is-1">This is the Portfolio Page</h1>
				<p>
					Class aptent taciti sociosqu ad litora torquent per conubia nostra, per
					inceptos himenaeos. Vestibulum ante ipsum primis in faucibus orci luctus
					et ultrices posuere cubilia curae; Duis consequat nulla ac ex consequat,
					in efficitur arcu congue. Nam fermentum commodo egestas.
				</p>
			</div>
		</section>
	</Fragment>
);

export default Portfolio;