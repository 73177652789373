/* eslint-disable jsx-a11y/img-redundant-alt */
  
import React, {Fragment} from "react";

const WhatWeDo = () => (
  <Fragment>
    <section className="main-testimonial">
		<div className="sec-shape">
			<span className="shape shape1"><img src="assets/images/shape1.png" alt="Shape" /></span>
			<span className="shape shape2"><img src="assets/images/shape2.png" alt="Shape" /></span>
			<span className="shape shape3 "><img src="assets/images/shape2.png" alt="Shape" /></span>
			<span className="shape shape4"><img src="assets/images/shape1.png" alt="Shape" /></span>
		</div>
		<div className="container">
			<div className="row align-items-center">
				<div className="col-lg-6 wow right-animation" data-wow-delay="0.4s">
					<div className="testimonial-img-box">
						<span className="testimonial-img-circle"></span>
						<div className="testimonial-img">
							<img width="501" height="662" src="assets/images/testimonial-img.png" alt="Testimonial Image" />
						</div>
					</div>
				</div>
				<div className="col-lg-6">
					<div className="testimonial-content wow left-animation" data-wow-delay="0.4s">
						<span className="sub-title">TESTIMONIAL</span>
						<h2 className="h2-title">See What Our Client Say’s</h2>
						<div className="main-testimonial-slider">
							<div className="testimonial-slider">
								<div className="testimonial-box">
									<div className="review-by">
										<div className="review-by-img back-img" style={{backgroundImage: `url('assets/images/testimonial-img1.jpg')`}}></div>
										<div className="review-by-text">
											<h3 className="h3-title">Johen Doe</h3>
											<p>Designer</p>
										</div>
									</div>
									<div className="testimonial-text">
										<p>Sed faucibus mauris mi. Suspendisse consequat sollicitudin eros, vitae vehicula quam luctus vitae. Cras vel mauris efficitur, fermentum purus vel, vulputate neque onec mattis blandit lacinia.</p>
									</div>
								</div>
								<div className="testimonial-box">
									<div className="review-by">
										<div className="review-by-img back-img" style={{backgroundImage: `url('assets/images/testimonial-img1.jpg')`}}></div>
										<div className="review-by-text">
											<h3 className="h3-title">Johen Doe</h3>
											<p>Designer</p>
										</div>
									</div>
									<div className="testimonial-text">
										<p>Sed faucibus mauris mi. Suspendisse consequat sollicitudin eros, vitae vehicula quam luctus vitae. Cras vel mauris efficitur, fermentum purus vel, vulputate neque onec mattis blandit lacinia.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
  </Fragment>
);

export default WhatWeDo;