import React, { useState } from 'react'
import { NavLink, withRouter } from "react-router-dom";
import Navbar from '../components/Navbar';



function Header(history) {
	// const [isOpen, setOpen] = useState(false);

	// const isAuth = !!localStorage.getItem("token");

	// const loginUser = () => {
	// 	localStorage.setItem("token", "some-login-token");
	// 	history.push("/profile/Vijit");
	// };

	// const logoutUser = () => {
	// 	localStorage.removeItem("token");
	// 	history.push("/");
	// };

	return (
		<header className="site-header">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-12">
						<div className="header-box">
							<div className="site-branding">
								<a href="index-2.html" title="Shivaa">
									<img src="assets/images/D-logo-3.png" alt="Logo" />
								</a>
							</div>
							<div className="header-menu">
								<Navbar />
								<div className="black-shadow"></div>
							</div>
							<div className={`header-search`}>
							{/* <div className={`header-search ${isOpen && "is-active"}`}> */}
							<NavLink className="sec-btn" activeClassName="active" to="/login" title="Get Started"><span>Get Started</span></NavLink>
								{/* {!isAuth ? (
									<NavLink className="sec-btn" activeClassName="active" to="/login" title="Get Started" onClick={loginUser}><span>Get Started</span></NavLink>
								) : (
									<NavLink className="sec-btn" activeClassName="active" to="/profile" title="Get Started" onClick={logoutUser}><span>Log Out</span></NavLink>
								)} */}
								<div className="extra-menu">
									<div className="extra-menu-icon">
										<NavLink className="navbar-item" activeClassName="active" to="/" exact title="Menu" >
											<img src="assets/images/menu-icon.png" alt="Menu Icon" className="lazy" />
										</NavLink>
									</div>
									<div className="black-shadow"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}

export default withRouter(Header)