  
import React, {Fragment} from "react";
import AboutUs from '../components/AboutUs';

const About = () => (
  <Fragment>
    <AboutUs />
    <section className="main-how-work">
		<div className="sec-shape">
			<span className="shape shape1"><img src="assets/images/shape1.png" alt="Shape" /></span>
			<span className="shape shape2"><img src="assets/images/shape2.png" alt="Shape" /></span>
			<span className="shape shape3 "><img src="assets/images/shape2.png" alt="Shape" /></span>
			<span className="shape shape4"><img src="assets/images/shape1.png" alt="Shape" /></span>
		</div>
		<div className="container">
			<div className="row">
				<div className="col-lg-6 wow right-animation" data-wow-delay="0.2s">
					<div className="how-work-img back-img" style={{backgroundImage: `url('assets/images/how-work-img.jpg')`}}>
						<a href  className="video-play-icon" title="Play Video"><span><i className="fa fa-play" aria-hidden="true"></i></span></a>
					</div>
				</div>
				<div className="col-lg-6 wow left-animation" data-wow-delay="0.2s">
					<div className="how-work-content">
						<span className="sub-title">HOW IT WORK</span>
						<h2 className="h2-title">Here To Help You Move Faster</h2>
						<div className="how-work-list">
							<div className="how-work-list-box">
								<span className="work-list-num">1.</span>
								<div className="how-work-list-text">
									<h3 className="h3-title">Analysis</h3>
									<p>Curabitur porttitor enim quis odio varius, tincidunt lobortis justo vulputate sed aliquam, lectus at euismod auctor.</p>
								</div>
							</div>
							<div className="how-work-list-box">
								<span className="work-list-num">2.</span>
								<div className="how-work-list-text">
									<h3 className="h3-title">Design & Development</h3>
									<p>Curabitur porttitor enim quis odio varius, tincidunt lobortis justo vulputate sed aliquam, lectus at euismod auctor.</p>
								</div>
							</div>
							<div className="how-work-list-box">
								<span className="work-list-num">3.</span>
								<div className="how-work-list-text">
									<h3 className="h3-title">SEO & Launch</h3>
									<p>Curabitur porttitor enim quis odio varius, tincidunt lobortis justo vulputate sed aliquam, lectus at euismod auctor.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</Fragment>
);

export default About;