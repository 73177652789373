  
import React, {Fragment} from "react";

const WhatWeDo = () => (
  <Fragment>
 <section className="main-services">
		<div className="sec-shape">
			<span className="shape shape1"><img src="assets/images/shape1.png" alt="Shape" /></span>
			<span className="shape shape2"><img src="assets/images/shape2.png" alt="Shape" /></span>
			<span className="shape shape3 "><img src="assets/images/shape2.png" alt="Shape" /></span>
			<span className="shape shape4"><img src="assets/images/shape1.png" alt="Shape" /></span>
		</div>
		<div className="container">
			<div className="row">
				<div className="col-12">
					<div className="center-title">
						<span className="sub-title" id="services">SERVICES</span>
						<h2 className="h2-title">What We Provide</h2>
					</div>
				</div>
			</div>
			<div className="services-list">
				<div className="row justify-content-center">
					<div className="col-xl-3 col-lg-4 col-md-6">
						<div className="service-box wow fadeup-animation" data-wow-delay="0.4s">
							<div className="service-box-text">
								<div className="service-img">
									<img src="assets/images/web-design.png" alt="Web Design" />
								</div>
								<h3 className="h3-title">Web Design</h3>
								<p>Nullam commodo venenatis purus non pellentesque.</p>
							</div>
						</div>
					</div>
					<div className="col-xl-3 col-lg-4 col-md-6">
						<div className="service-box wow fadeup-animation" data-wow-delay="0.5s">
							<div className="service-box-text">
								<div className="service-img">
									<img src="assets/images/idea-research.png" alt="Idea & Research" />
								</div>
								<h3 className="h3-title">Idea & Research</h3>
								<p>Nullam commodo venenatis purus non pellentesque.</p>
							</div>
						</div>
					</div>
					<div className="col-xl-3 col-lg-4 col-md-6">
						<div className="service-box wow fadeup-animation" data-wow-delay="0.6s">
							<div className="service-box-text">
								<div className="service-img">
									<img src="assets/images/web-development.png" alt="Web Development" />
								</div>
								<h3 className="h3-title">Web Development</h3>
								<p>Nullam commodo venenatis purus non pellentesque.</p>
							</div>
						</div>
					</div>
					<div className="col-xl-3 col-lg-4 col-md-6">
						<div className="service-box wow fadeup-animation" data-wow-delay="0.7s">
							<div className="service-box-text">
								<div className="service-img">
									<img src="assets/images/seo-marketing.png" alt="SEO & Marketing" />
								</div>
								<h3 className="h3-title">SEO & Marketing</h3>
								<p>Nullam commodo venenatis purus non pellentesque.</p>
							</div>
						</div>
					</div>
					<div className="col-xl-3 col-lg-4 col-md-6">
						<div className="service-box wow fadeup-animation" data-wow-delay="0.8s">
							<div className="service-box-text">
								<div className="service-img">
									<img src="assets/images/business-analytics.png" alt="Business Analytics" />
								</div>
								<h3 className="h3-title">Business Analytics</h3>
								<p>Nullam commodo venenatis purus non pellentesque.</p>
							</div>
						</div>
					</div>
					<div className="col-xl-3 col-lg-4 col-md-6">
						<div className="service-box wow fadeup-animation" data-wow-delay="0.9s">
							<div className="service-box-text">
								<div className="service-img">
									<img src="assets/images/support.png" alt="24x7 Support" />
								</div>
								<h3 className="h3-title">24x7 Support</h3>
								<p>Nullam commodo venenatis purus non pellentesque.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	
  </Fragment>
);

export default WhatWeDo;