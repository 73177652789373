import React, {Fragment} from "react";
import Banner from '../components/Banner';
import WhateWeDo from '../components/WhatWeDo';
import AboutUs from '../components/AboutUs';
import Testimonial from '../components/Testimonial';

const Home = () => (
  <Fragment>
      <Banner />
      <WhateWeDo />
      <AboutUs />
      <Testimonial />
  </Fragment>
);

export default Home;